<template>
  <div class="write-page-heng-container">
    <WritePinyinPage
      :pinyinImgList="pinyinImgList"
      :pinyinList="pinyinList"
      :strokeTimesList="strokeTimesList"
      :buttonList="buttonList"
    />
  </div>
</template>
<script>
import WritePinyinPage from "@/components/Course/CoursePage/WritePinyinPage";

export default {
  data() {
    return {
      pinyinList: ["g", "k", "h"], //ü
      strokeTimesList: [1, 1, 1],
      pinyinImgList:[
        {
           pinyinImg: require('@/assets/img/14-Pinyin/chapter-04/pinyin-g-gif.svg'),
           tipsImg: require('@/assets/img/14-Pinyin/chapter-04/chapter-04-g.gif'),
        },
        {
           pinyinImg: require('@/assets/img/14-Pinyin/chapter-04/pinyin-k-gif.svg'),
           tipsImg: require('@/assets/img/14-Pinyin/chapter-04/chapter-04-k.gif'),
        },
        {
           pinyinImg: require('@/assets/img/14-Pinyin/chapter-04/pinyin-h-gif.svg'),
           tipsImg: require('@/assets/img/14-Pinyin/chapter-04/chapter-04-h.gif'),
        },
        
        
      ],
      buttonList: [
        {
          image: require("@/assets/img/14-Pinyin/menu/pinyin-g.svg"),
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/14-Pinyin/menu/pinyin-k.svg"),
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/14-Pinyin/menu/pinyin-h.svg"),
          startNumberRange: 3,
          endNumberRange: 3,
        },
      ],
    };
  },
  components: {
    WritePinyinPage,
  },
};
</script>
<style lang="scss" scoped>
.write-page-heng-container {
  width: 100%;
  height: 100%;
}
</style>
