<template>
  <div class="sing-page-container" :class="{ 'layout-border': !isReviewPage }">
    <SingPage
      :singList="singList"
      :songInfo="songInfo"
      :timeStampList="timeStampList"
      :isReviewPage="isReviewPage"
    ></SingPage>
  </div>
</template>
<script>
import SingPage from "@/components/Course/CoursePage/SingPage";

export default {
  props: {
    isReviewPage: {
      type: Boolean,
    },
  },
  components: {
    SingPage,
  },
  data() {
    return {
      songInfo: {
        songUrl: require("@/assets/audio/songs/lesson-31.mp3"),
      },
      timeStampList: [8, 12, 16.3, 20.1, 24.3, 28, 32, 36, 39.6, 44, 49],
      singList: {
        lyricsArr: [
          {
            id: 1,
            chineseWords: "g, k, h,",
          },
          {
            id: 2,
            chineseWords: "j, q, x",
          },
          {
            id: 3,
            chineseWords: "g - ē - gēge  (哥哥)",
          },
          {
            id: 4,
            chineseWords: "k - ě - kě (渴)",
          },
          {
            id: 5,
            chineseWords: "h - ē - hēhē（呵呵）",
          },
          {
            id: 6,
            chineseWords: "j - ī - jī（鸡）",
          },
          {
            id: 7,
            chineseWords: "q - ǚ - qǔ（曲）",
          },
          {
            id: 8,
            chineseWords: "x - ü - xū（嘘）",
          },
          {
            id: 9,
            chineseWords: "gēge, kě,",
          },
          {
            id: 10,
            chineseWords: "hēhē, jī, qǔ, xū（哥哥、渴、呵呵、鸡、曲、嘘）",
          },
        ],
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.singpage-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
}
</style>
