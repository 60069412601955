<template>
  <div class="recognition-page-one">
    <RecognitionPage
      :backgroundImage="bgImg"
      :wordObj="wordDetail"
      :isLongPinyin="true"
    />
  </div>
</template>

<script>
import RecognitionPage from "@/components/Course/CoursePage/RecognitionPage";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/14-Pinyin/chapter-04/pinyin-u-change.svg"),
      wordDetail: {
        lesson7: true,
        pinName: "",
        chineseName: "",
        englishName: "",
        hanziClass: "pinyin font-hanzi-large",
        multiEnglishName: ["j+ü→ju", "q+ü→qu", "x+ü→xu"],
        songUrl: require("@/assets/audio/L1/3-Pinyin-map/ju-qu-xu.mp3"),
      },
    };
  },
  components: {
    RecognitionPage,
  },
};
</script>

<style lang="scss" scoped>
.recognition-page-one {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
