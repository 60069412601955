<template>
  <div class="game-container">
    <PinyinPuzzleGame :optionList="optionList" :answerList="answerList"/>
  </div>
</template>
<script>
import PinyinPuzzleGame from "@/components/Course/GamePage/PinyinPuzzleGame";

export default {
  components: {
    PinyinPuzzleGame,
  },
  data() {
    return {
      optionList: [
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-31/1-1.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-31/1-2.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-31/1-3.svg"),
            showObj: true,
          },
        ],
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-31/2-1.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-31/2-2.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-31/2-3.svg"),
            showObj: true,
          },
        ],
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-31/3-1.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-31/3-2.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-31/3-3.svg"),
            showObj: true,
          },
        ],
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-31/4-1.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-31/4-2.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-31/4-3.svg"),
            showObj: true,
          },
        ],
      ],

      answerList: [
        {
          id: 1,
          type: 1,
          audioUrl: require("@/assets/audio/L1/3-Pinyin-syllables/ku1.mp3"),
          isCorrect: false,
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-31/1-base.svg"),
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-31/1-correct.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-31/1-puzzle.svg"),
        },
        {
          id: 3,
          audioUrl: require("@/assets/audio/L1/3-Pinyin-syllables/ju4.mp3"),
          type: 1,
          isCorrect: false,
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-31/2-base.svg"),
          secondImgBefore: require("@/assets/img/16-GAMES/G46-puzzle/lesson-31/2-base-1.svg"),
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-31/2-correct.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-31/2-puzzle.svg"),
        },
        {
          id: 2,
          audioUrl: require("@/assets/audio/L1/3-Pinyin-syllables/guo2.mp3"),
          type: 1,
          isCorrect: false,
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-31/3-base.svg"),
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-31/3-correct.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-31/3-puzzle.svg"),
        },
        {
          id: 1,
          audioUrl: require("@/assets/audio/L1/3-Pinyin-syllables/xia1.mp3"),
          type: 1,
          isCorrect: false,
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-31/4-base.svg"),
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-31/4-correct.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-31/4-puzzle.svg"),
        },
      ],
    };
  },
};
</script>
<style scoped lang="scss">
.game-container {
  width: 100%;
  height: 100%;
}
</style>
