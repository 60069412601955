<template>
  <div class="game-container">
    <RecognitionPinyinBasketBallGame
      :answerList="answerList"
      :optionList="optionList"
      :audioList="audioList"
      :totalStarNumber="6"
    />
  </div>
</template>

<script>
import RecognitionPinyinBasketBallGame from "@/components/Course/GamePage/RecognitionPinyinBasketBallGame";
export default {
  data() {
    return {
      lessonId: 7,
      answerList: [
        {
          id: 1,
          sound: "",
          showObj: true,
        },
        {
          id: 3,
          sound: "",
          showObj: true,
        },
        {
          id: 2,
          sound: "",
          showObj: true,
        },
        {
          id: 1,
          sound: "",
          showObj: true,
        },
        {
          id: 3,
          sound: "",
          showObj: true,
        },
        {
          id: 2,
          sound: "",
          showObj: true,
        },
      ],

      optionList: [],
      audioList:[
        require("@/assets/audio/L1/2-Pinyin/j.mp3"),
        require("@/assets/audio/L1/2-Pinyin/x.mp3"),
        require("@/assets/audio/L1/2-Pinyin/q.mp3"),
        require("@/assets/audio/L1/2-Pinyin/j.mp3"),
        require("@/assets/audio/L1/2-Pinyin/x.mp3"),
        require("@/assets/audio/L1/2-Pinyin/q.mp3"),
      ]
    };
  },
//  mounted () {
//     let newArr=[]
//     for (let i = 0; i < 7; i++) {
//       const ele =  [{
//             id: 1,
//             bottomImg: require("@/assets/img/16-GAMES/G45-basketball/basketball-zh.svg"),
//             showObj: true,
//           },
//           {
//             id: 2,
//             bottomImg: require("@/assets/img/16-GAMES/G45-basketball/basketball-ch.svg"),
//             showObj: true,
//           },
//           {
//             id: 3,
//             bottomImg: require("@/assets/img/16-GAMES/G45-basketball/basketball-sh.svg"),
//             showObj: true,
//           },
//           {
//             id: 4,
//             bottomImg: require("@/assets/img/16-GAMES/G45-basketball/basketball-r.svg"),
//             showObj: true,
//           },]
//           newArr.push(ele)
//     }
//     //  return newArr
//     this.optionList = newArr
//     console.log(this.optionList,'qqqqqqqqqqqqq');
   
    
//   },
  components: {
    RecognitionPinyinBasketBallGame,
  },

  mounted () {
    let newArr=[]
    for (let i = 0; i < 6; i++) {
      const ele =  [{
            id: 1,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/chapter-04/basketball-j.svg"),
            showObj: true,
          },
          {
            id: 2,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/chapter-04/basketball-q.svg"),
            showObj: true,
          },
          {
            id: 3,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/chapter-04/basketball-x.svg"),
            showObj: true,
          },
          {
            id: 4,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/chapter-04/basketball-g.svg"),
            showObj: true,
          },]
          newArr.push(ele)
    }
    //  return newArr
    this.optionList = newArr
    console.log(this.optionList,'qqqqqqqqqqqqq');
   
    
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>